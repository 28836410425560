import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
// import 'swiper/swiper-bundle.min.css';
import "../../index.css"; // Ensure this imports your custom styles

import { fieldMarketingAgent } from "../../assets/Strings";
import { useNavigate } from "react-router-dom";
import icon from "../../assets/Icon.webp";
import { LocationIcon } from "../../assets/WelcomeScreenImage";
import { themeColors } from "../../theme";
import Icon from "../../assets/Icon.webp";
import StarRating from "../../components/StarRating";
import { removeSpacesAndConvertLowercase } from "../basicHelper";

export const PillButton = (props) => {
    const { label, selected, onClick } = props;

    return (
        <button
            onClick={onClick}
            className={`px-3 rounded-full mr-2 mb-2 flex flex-row justify-center w-40 md:w-32 lg:w-52 border-2 md:border-4 border-secondary
			${selected ? "bg-secondary text-tertiary" : "bg-tertiary text-secondary"} 
			hover:bg-secondary hover:text-tertiary`}
        >
            <p className="text-sm lg:text-lg font-bold py-2">{label}</p>
        </button>
    );
};

export const BigButton = (props) => {
    return (
        <button
            className={`${props.textColor} ${props.bg} inline-block rounded-full mx-2 mt-2 mb-2 px-6 lg:px-10 hover:bg-primary focus:outline-none focus:ring focus:border-primary ${props.width}`}
            onClick={props.onClick}
        >
            <p className="text-xs md:text-sm font-bold px-0 lg:px-2 py-1 lg:py-2">{props.label}</p>
        </button>
    );
};

export const Placard = (props) => {
    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState(props.gymDisplayName);
    const [imageToShow, setImageToShow] = useState(Icon);

    // Function to handle screen size and truncation
    const truncateName = (name, maxLength) => {
        return name.length > maxLength
            ? name.substring(0, maxLength) + "..."
            : name;
    };

    useEffect(() => {
        // Function to handle screen resizing
        const handleResize = () => {
            const isSmall = window.innerWidth <= 768;
            // Truncate to 50 characters if the screen is small
            if (!props.hidden) {
                setDisplayName(
                    isSmall ? truncateName(props.gymDisplayName, 18) : truncateName(props.gymDisplayName, 25)
                );
            }
        };

        if (props.image[0]) {
            setImageToShow(convertToDirectLink(props.image[0]))
        } else {
            setImageToShow(Icon)
        }

        // Check on initial render
        handleResize();
        // Listen for window resize
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [props.gymDisplayName, props.hidden, props.image]);

    const handleClick = () => {
        if (props.hidden) {
            navigate(`/blogs/${removeSpacesAndConvertLowercase(displayName)}`, { state: props });
        }
    };

    // Handles Navigation directly from placard button
    const handleNavigationFromPlaCard = () => {
        if (!props.gymName) return;

        // creates slugs for gym names+locality and then navigates to the gym page
        // const formattedLocality = props.locality.replace(/\s+/g, "").toLowerCase();
        navigate(`/${props.city}/gyms/${props.gymName}`, {
            state: { props },
        });
    };

    return (
        <div
            onClick={props.hidden ? handleClick : handleNavigationFromPlaCard}
            className={`flex flex-col h-fit min-w-[140px] md::min-w-72 rounded-3xl mx-4 lg:mx-8 cursor-pointer scale-90 lg:hover:scale-[103%] hover:shadow-xl transition-all duration-500 ${props.bg}`}
        >
            <img
                src={imageToShow}
                alt={displayName}
                className="flex h-36 w-84 lg:h-56 m-2 lg:m-4 rounded-2xl object-cover"
            />
            {/* Display name with truncation for small screens */}
            <p
                className={`flex px-4 text-lg lg:text-3xl ${props.textColor} font-bold text-left overflow-hidden ${props.hidden ? '' : 'whitespace-nowrap'} text-ellipsis`}>
                {displayName}
            </p>

            {/* {props.cityDisplayName && 
				<div className="flex flex-row lg:px-0 mb-4 mx-3 mt-1">
					{!props.hidden ? (
						<>
							<LocationIcon className="w-5 lg:w-7 h-4 lg:h-6" color={props.locationIconColor} />
							<p className={text-sm lg:text-xl ${props.textColor}  font-bold}>
								{props.cityDisplayName}
							</p>
						</>
					) : (
						<p className={text-sm lg:text-xl ${props.textColor} font-bold mx-1 text-left}>
							{props.cityDisplayName}
						</p>
					)}
				</div>
			} */}
            {props.locality && (
                <div className="flex flex-row lg:px-0 mb-4 mx-3 mt-1">
                    {!props.hidden ? (
                        <>
                            <LocationIcon
                                className="w-5 lg:w-7 h-4 lg:h-6"
                                color={props.locationIconColor}
                            />
                            <p className={`text-sm lg:text-xl ${props.textColor} font-bold text-left`}>
                                {props.locality}
                            </p>
                        </>
                    ) : (
                        <p
                            className={`text-sm lg:text-xl ${props.textColor} font-bold mx-1 text-left`}
                        >
                            {props.locality}
                        </p>
                    )}
                </div>
            )}

            {!props.hidden && (
                <div className="flex justify-between items-center">
                    <button
                        onClick={handleNavigationFromPlaCard}
                        className={`flex text-sm lg:text-lg ${props.bg === "bg-secondary" ? "bg-secondary" : "bg-tertiary"
                            } font-bold ${props.textColor === "text-secondary"
                                ? "text-tertiary"
                                : "text-secondary"
                            } w-fit rounded-full px-4 py-1 mx-4 mb-4`}
                    >
                        EXPLORE
                    </button>
                </div>
            )}
            {/* For Displaying Price and EXPLORE Uncomment this */}
            {/* {!props.hidden && (
				<div className="flex justify-between items-center px-4 lg:px-6 mb-4">
					<p className={text-xs lg:text-sm ${props.textColor} font-bold whitespace-nowrap}>
						From ₹{Math.round(props.price / 12)}/month
					</p>
	
					<button
						onClick={handleNavigationFromPlaCard}
						className={text-sm lg:text-lg ${props.bg === 'bg-secondary' ? 'bg-secondary' : 'bg-tertiary'} font-bold ${props.textColor === 'text-secondary' ? 'text-tertiary' : 'text-secondary'} rounded-full px-4 py-1}
					>
						EXPLORE
					</button>
				</div>
			)} */}
        </div>
    );
};

export const Carousel = ({ slides }) => {
    return (
        <div className="carousel-container relative w-full">
            <Swiper
                spaceBetween={10}
                slidesPerView={window.innerWidth <= 640 ? 1.8 : 2}   // more value == reduced gap between cards
                centeredSlides={false}
            // modules={[Navigation, Pagination, Scrollbar, A11y]}
            // navigation
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: false }}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index} className="carousel-slide flex gap-10">
                        {slide}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

// Handles Jobs and Lists for Fitness centres and blogs
export const ListCard = (props) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [data, setData] = useState(props);
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate(`/${props.city}/gyms/${props.name}`, {
            state: { props },
        });
    };

    const handleButtonClick = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        try {
            if (props.image && props.image.length > 0) {
                const transformed = getFinalImage(props.image);
                setData((prevData) => ({
                    ...prevData,
                    image: transformed,
                }));
            }
        } catch (error) {
            console.error("error in image : ", error);
        }
    }, [props.image]);

    const handleListCardClick = () => {
        if (props.buttonText === "EXPLORE") {
            handleNavigation();
        }
    };

    const availableFacilities = props.facilities ? Object.keys(props.facilities).filter(facility => props.facilities[facility]) : [];

    // Function to format facility names (some facilities were like "Personal Training" and some were like "ValetParking" so converting to "Valet Parking")
    const formatFacilityName = (name) => {
        return name.replace(/([a-z])([A-Z])/g, "$1 $2"); // Inserts a space before each uppercase letter following a lowercase letter
    };

    return (
        <div
            id="Full Card"
            className={`flex flex-row bg-secondary w-full rounded-3xl p-2 lg:p-4 my-6 ${props.buttonText === "EXPLORE" ? 'hover:shadow-xl hover:scale-[108%] cursor-pointer' : ''} transition-all duration-500 min-h-[18vh] lg:min-h-[25vh]`}
            onClick={handleListCardClick}
        >
            {props.extraText === "Jobs" ? (
                <img
                    src={icon}ˀ
                    alt="Logo"
                     className="lg:w-1/3 w-1/4 rounded-3xl"
                />
            ) : (
                <img
                    src={data.image || Icon}
                    alt="Logo"
                    className="min-h-full w-1/3 lg:w-1/4 rounded-3xl object-cover"
                />
            )}
            <div
                id="Text Part"
                className="flex flex-col h-fit bg-secondary w-3/4 rounded-2xl"
            >
                <div
                    id="Top"
                    className="flex flex-col lg:flex-row justify-between px-4"
                >
                    <div className="flex flex-col items-start">
                        <p className="text-lg lg:text-4xl text-tertiary font-bold text-left">
                            {props.extraText === 'Jobs' ? props.name : props.gymDisplayName}
                        </p>
                        <div className="flex flex-row py-2 gap-x-1">
                            <LocationIcon
                                className="w-3 lg:w-7 h-4 lg:h-8"
                                color={themeColors.lightAccent}
                            />
                            <p className="text-sm lg:text-2xl text-tertiary font-bold text-left">
                                {props.extraText === 'Jobs' ? props.location : props.locality}
                            </p>
                        </div>
                    </div>

                    <div className="flex lg:hidden lg:pt-3 items-start pb-3">
                        {props.extraText === "Jobs" ? (
                            <button
                                onClick={handleButtonClick}
                                className="flex text-[10px] lg:text-xl text-secondary font-bold bg-tertiary rounded-full lg:mx-4 my-auto px-2 lg:px-8 py-1 lg:py-2"
                            >
                                View Description
                            </button>
                        ) : (
                            <p className="text-[10px] lg:text-xl text-tertiary font-semibold text-left">
                                Membership Starts From ₹{Math.round(props.price / 12)}/Month
                            </p>
                        )}
                    </div>

                    {/* Stars and Reviews at teh top for bigger screens */}
                    {/* {props.stars?
				<div className="hidden lg:flex flex-col items-end">
				<div className="text-lg lg:text-3xl text-tertiary font-bold">
					{[...Array(parseInt(props.rating))].map((_, starIndex) => (
					<span key={starIndex} className='text-sm lg:text-4xl text-tertiary'>&#9733;</span>
					))}
				</div>
				<p className="text-sm lg:text-xl text-tertiary font-bold">{props.NoOfReview} Reviews</p>
				</div>
				:
				null} */}
                    <StarRating rating={props.rating} NoOfReview={props.NoOfReview} />
                </div>

                {/* Hiding this on mobile for now */}
                <div className="lg:flex justify-start px-4 text-white font-semibold gap-5 hidden">
                    {/* <div> Facilities </div> */}
                    <div className='flex gap-4 flex-wrap'>
                        {availableFacilities.length > 0 ? availableFacilities.map((facility, index) => (
                            <div key={index} className='bg-primary px-4 rounded-full flex items-center text-white'>
                                {formatFacilityName(facility)}
                            </div>
                        )) : null}
                    </div>
                </div>


                {/* Line */}
                {props.stars ? (
                    <div className="flex ml-4 mr-2 lg:mx-4 border-t-2 border-tertiary lg:my-4"></div>
                ) : null}

                {/* Stars and Reviews at bottom for smaller screen */}
                <div
                    id="Bottom"
                    className="flex flex-row w-full justify-between items-center pl-4"
                >
                    {props.stars ? (
                        <div className="flex lg:hidden flex-col items-start">
                            <div className="text-lg lg:text-3xl text-tertiary font-bold">
                                {[...Array(parseInt("5"))].map((_, starIndex) => (
                                    <span
                                        key={starIndex}
                                        className="text-sm lg:text-4xl text-tertiary"
                                    >
                                        &#9733;
                                    </span>
                                ))}
                            </div>
                            {/* <p className="text-lg lg:text-3xl text-tertiary font-bold">Stars</p> */}
                            <p className="text-[10px] lg:text-xl text-tertiary font-bold -mt-1">
                                {props.NoOfReview} Reviews
                            </p>
                        </div>
                    ) : null}


                    {/* Extra Text at bottom for bigger screen */}
                    <div className="lg:flex hidden items-start">
                        {props.extraText === "Jobs" ? (
                            <button
                                onClick={handleButtonClick}
                                className="flex text-[10px] lg:text-xl text-secondary font-bold bg-tertiary rounded-full my-auto mx-4 lg:px-6 py-1 lg:py-2"
                            >
                                View Description
                            </button>
                        ) : (
                            <p className="text-[10px] lg:text-2xl text-tertiary font-semibold text-left">
                                Membership Starts From ₹{Math.round(props.price / 12)}/Month
                            </p>
                        )}
                    </div>
                    {props.hidden === false ? (
                        ""
                    ) : props.buttonText === "Apply" ? (
                        <a
                            href="https://forms.gle/JYR3i4z7yRU4Vf836"
                            className="flex text-[10px] lg:text-xl text-secondary font-bold bg-tertiary rounded-full lg:mx-4 my-auto px-2 lg:px-4 py-1 lg:py-2 lg:mt-4"
                        >
                            {props.buttonText}
                        </a>
                    ) : (
                        <button
                            onClick={handleNavigation}
                            className="flex text-[10px] lg:text-xl text-secondary font-bold bg-tertiary rounded-full lg:mx-4 mr-2 px-3 lg:px-5 py-1 lg:py-2"
                        >
                            {props.buttonText}
                        </button>
                    )}
                    {/* <a href={props.buttonText==='Apply'?"https://forms.gle/JYR3i4z7yRU4Vf836":'/ProductPortfolio'} className="flex text-[10px] lg:text-xl text-secondary font-bold bg-tertiary rounded-full lg:mx-4 my-auto px-2 py-1 lg:py-2 lg:mt-4">{props.buttonText}</a> */}
                </div>
            </div>

            {isPopupOpen && (
                <JobDescriptionPopup
                    text={fieldMarketingAgent}
                    handleClosePopup={handleClosePopup}
                />
            )}
        </div>
    );
};

export const JobDescriptionPopup = ({ text, handleClosePopup }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-tertiary bg-opacity-50 p-2">
            <div className="bg-secondary text-black p-4 rounded-2xl border-4 border-secondary overflow-y-auto max-h-[65vh] max-w-[90vw] w-full sm:w-auto">
                <div className="px-4 py-2 flex flex-col text-left">
                    <h2 className="text-2xl font-bold mb-4">About the job</h2>
                    <h3 className="text-xl font-semibold">Position Overview :</h3>
                    <p className="mb-4">{text.positionOverview}</p>

                    <h3 className="text-xl font-semibold">Responsibilities :</h3>
                    <ul className="list-disc list-inside pl-4 mb-4">
                        {text.responsibilities.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>

                    <h3 className="text-xl font-semibold">Qualifications :</h3>
                    <ul className="list-disc list-inside pl-4 mb-4">
                        {text.qualifications.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>

                    <h3 className="text-xl font-semibold">Benefits :</h3>
                    <ul className="list-disc list-inside pl-4 mb-4">
                        {text.benefits.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>

                    <p className="mb-4">{text.closing}</p>

                    <button
                        onClick={handleClosePopup}
                        className="bg-tertiary text-black rounded font-semibold text-lg px-2 mx-auto"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export const ClickOpen = ({ heading, details, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <>
            <div className="flex lg:px-4 py-2 justify-between items-center">
                <p className="flex text-secondary text-lg lg:text-2xl font-bold">
                    {heading}:
                </p>
                <button
                    onClick={toggleVisibility}
                    className="text-secondary text-2xl lg:text-4xl font-bold"
                >
                    {isVisible ? "-" : "+"}
                </button>
            </div>
            {isVisible && (
                <div className="flex flex-col lg:px-4 pb-2">
                    {details && (
                        <p className="text-primary text-sm lg:text-lg">{details}</p>
                    )}
                    {children}
                </div>
            )}
        </>
    );
};

/**
 * Displays a list
 * @param {String} title
 * @param {List} items
 * @returns
 */
export const ListDisplay = ({ items }) => {
    const displayItems =
        items && items.length > 0 ? items : ["Nothing to Display"];

    return (
        <div className="flex flex-col">
            <ul className="list-disc ml-6">
                {displayItems.map((item, index) => (
                    <li
                        key={index}
                        className="text-secondary text-start text-base lg:text-lg"
                    >
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Function to convert Google Drive link to a direct link
export const convertToDirectLink = (url) => {
    if (url === '') return null;
    const fileIdMatch = url.match(/\/d\/([a-zA-Z0-9_-]+)\//);
    return fileIdMatch !== null
        ? `https://drive.google.com/thumbnail?id=${fileIdMatch[1]}`
        : url;
};

export const getFinalImage = (imageURL) => {

    // Check if imageURL is a string, return the converted link if so
    if (typeof imageURL === 'string') {
        return convertToDirectLink(imageURL);
    }

    // Check if imageURL is undefined, null, or not an array
    if (!imageURL || !Array.isArray(imageURL) || !imageURL[0]) {
        return null; // Return null if imageURL is invalid
    }

    // Convert the first element of the array
    return convertToDirectLink(imageURL[0]);
};


export const ClassPlacard = ({
    classId, className, classSchedule, priceForMembers, priceForNonMembers, trainer, isActive, availableSlots, totalSlots, gymName, gymLocation
}) => {
    const navigate = useNavigate();

    // Navigate to class booking page
    const handleClassClick = () => {
        navigate(`/gym/${classId}/class/${className}`, {
            state: {
                className,
                gymName,
                classSchedule,
                priceForMembers,
                priceForNonMembers,
                trainer,
                availableSlots,
                totalSlots,
            },
        });
    };

    return (
        <>
            {isActive && (
                <div
                    className={"flex flex-col min-w-[140px] rounded-3xl p-4 lg:mx-8 bg-secondary"}
                    onClick={handleClassClick}
                >
                    {/* Display name with truncation for small screens */}
                    <div className="flex flex-row justify-between">
                        <p className={'text-lg lg:text-3xl text-tertiary font-bold text-left'}>
                            {className ? className : "Boxing"}
                        </p>
                        <p className="text-xs lg:text-sm text-tertiary font-bold pt-4">
                            Available Slots: {availableSlots} / {totalSlots}
                        </p>
                    </div>

                    <p className={'text-sm lg:text-xl text-tertiary font-bold pt-2 text-left'}>
                        {gymName}, {gymLocation ? gymLocation : "Delhi"}
                    </p>
                    {/* <p className={'text-sm lg:text-xl text-tertiary font-bold py-2 text-left'}>
                    {classSchedule}
                </p> */}
                    <p className={'text-sm lg:text-xl text-tertiary font-bold py-2 text-left'}>
                        by {trainer}
                    </p>
                    <p className="text-xs lg:text-sm text-tertiary font-bold whitespace-nowrap">
                        From ₹{priceForMembers}/session (Members) | ₹{priceForNonMembers}/session (Non-Members)
                    </p>
                </div>
            )}
        </>
    )
};
