import React, { useEffect, useState, useRef, Suspense } from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO
import '../../App.css';
import { NearYou } from '../../helperFunctions/MarketplaceHelpers/NearYou';
import { themeColors } from '../../theme';
import Tagline from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Tagline';
import ContactUs from '../../helperFunctions/MarketplaceHelpers/ContactUs';
import Blogposts from '../../helperFunctions/MarketplaceHelpers/Blogposts';
import GetTheApp from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/GetTheApp';
import Map from '../../helperFunctions/MarketplaceHelpers/MarketplaceLandingPageComponents/Mapbox';
import { removeSpacesAndConvertLowercase } from '../../helperFunctions/basicHelper';
import { analytics } from '../../config/firebase';
import { logEvent } from 'firebase/analytics';
import CTACarousel from './CTACarousel';
import { fetchAllGyms } from '../../helperFunctions/MarketplaceHelpers/MarketplaceHelper';

// Custom hook to handle scroll-based fade-in and fade-out based on scroll direction
const useScrollFadeInOut = () => {
    const [isVisible    , setIsVisible    ] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const elementRef                        = useRef(null);

    // initally page is on top, not scrolled down
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        const observerOptions = {
            root: null, // The viewport
            rootMargin: "0px",
            threshold: [0.20, 0.25] // Trigger between 20% and 25% of the component's visibility
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const currentScrollTop = window.scrollY || document.documentElement.scrollTop;

                // If scrolling down and component is visible -> fade in
                if (entry.isIntersecting && currentScrollTop > lastScrollTop) {
                    setIsVisible(true);
                }
                // If scrolling up and component is out of view -> fade out
                else if (!entry.isIntersecting && currentScrollTop < lastScrollTop) {
                    setIsVisible(false);
                }

                // Update last scroll position
                setLastScrollTop(currentScrollTop);
            });
        }, observerOptions);

        const currentElement = elementRef.current;
        if (currentElement) observer.observe(currentElement);

        return () => {
            if (currentElement) observer.unobserve(currentElement);
        };
    }, [lastScrollTop]);

    return { ref: elementRef, isVisible };
};

// Wrapper component to apply the fade effect to children components
const ComponentWithFadeInOut = ({ children }) => {
    const { ref, isVisible } = useScrollFadeInOut();

    return (
        <div ref={ref} className={`fade-section ${isVisible ? 'fade-in' : 'fade-out'}`}>
            {children}
        </div>
    );
};

function Marketplace({ selectedCity }) {
    const [gymArray,    setGymArray ]   =   useState([]);
    const [, setLoading  ] = useState(null);

    useEffect(() => {
        setLoading(true);
        const fetchGymsAndClasses = async () => {
            // const classes = await getAllClasses(selectedCity);
            // setClassList(classes);
            const allGyms = await fetchAllGyms(selectedCity);
            setGymArray(allGyms);
            setLoading(false);
        };
        fetchGymsAndClasses();
    }, [selectedCity]);

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'Marketplace', {
            page_path: ["Home", selectedCity],
        });
    }, [selectedCity]);

    return (
        <>
            {/* SEO: Helmet for Title, Meta Descriptions, and Keywords */}
            <Helmet>
                <title>Explore Gyms and Fitness Centers in {selectedCity} | Verified Gym Memberships - Train Rex</title>
                <meta
                    name="description"
                    content={`Find top gyms, fitness centers, and workout classes in ${selectedCity}. Discover the best fitness options and join the fitness community with Train Rex.`}
                />
                <meta
                    name="keywords"
                    content={`best gyms in Delhi, best zumba classes in Delhi, 
                        best gyms in Ghaziabad, fitness classes in Ghaziabad, 
                        gyms in Noida, fitness classes in Noida, 
                        best gyms in Delhi NCR, best fitness classes in Delhi NCR, 
                        fitness centers, fitness marketplace, health and wellness, holistic fitness solution, gym memberships, improve fitness journey, motivational fitness app, health and fitness ecosystem, Train Rex`}
                />
                <link rel="canonical" href={`https://trainrexofficial.com/marketplace/${selectedCity}`} />
            </Helmet>

            <div className="bg-tertiary w-full pt-16 lg:pt-28">
                {/* <PictureAndSearchFiltering /> */}
                <div className="w-full">
                    <CTACarousel selectedCity={selectedCity}/>
                </div>

                <Tagline />

                {/* Fitness centers section */}
                
                <NearYou
                    heading="CHECK OUT FITNESS CENTRES NEAR YOU"
                    headingMobile="FITNESS CENTRES NEAR YOU"
                    bg="bg-tertiary"
                    headingColor="text-secondary"
                    size="mx-auto lg:mx-40"
                    locationIconColor={themeColors.lightAccent}
                    selectedCity={removeSpacesAndConvertLowercase(selectedCity)}
                    gymArray={gymArray}
                />

                {/* Map Section */}
                {/* Lazy-loaded Map Section */}
                <Suspense fallback={<div>Loading Map...</div>}>
                    <Map selectedCity={selectedCity} currentPage="marketplace" gymArray={gymArray}/>
                </Suspense>

                {/* Lazy-loaded components for Get the TrainRex App, Blog List, and Contact Us */}
                <ComponentWithFadeInOut>
                    <Suspense fallback={<div>Loading Get The App...</div>}>
                        <GetTheApp />
                    </Suspense>
                </ComponentWithFadeInOut>

                {/* <ComponentWithFadeInOut>
                    <Partners />
                </ComponentWithFadeInOut> */}

                <ComponentWithFadeInOut>
                    <Suspense fallback={<div>Loading Blogposts...</div>}>
                        <Blogposts selectedCity={removeSpacesAndConvertLowercase(selectedCity)} />
                    </Suspense>
                </ComponentWithFadeInOut>

                <ComponentWithFadeInOut>
                    <Suspense fallback={<div>Loading Contact Us...</div>}>
                        <ContactUs />
                    </Suspense>
                </ComponentWithFadeInOut>
            </div>
        </>
    );
}

export default Marketplace;
