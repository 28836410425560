import { collection, getDoc, doc, getDocs, updateDoc } from "@firebase/firestore";
import { db, dbGym } from "../../config/firebase";
import axios from "axios";
import { gymList, cityList } from "../../components/Databases";
import { removeSpacesAndConvertLowercase } from "../basicHelper";
import { sortGyms } from "./GymsSorting";
import { getSellingPrice } from "./gymDataHelpers/PriceHelpers";

/*
 * Fetches Top 3 Gyms in user's city
 */
export const fetchTopGyms = async () => {
    const cityWiseData = [];

    try {
        const citiesSnapshot = await getDocs(collection(db, cityList));

        if (citiesSnapshot.empty) {
            return cityWiseData;
        }

        for (const cityDoc of citiesSnapshot.docs) {
            const city = cityDoc.id;
            let userCount = 0;

            const gymsSnapshot = await getDocs(collection(db, cityList, city, gymList));

            if (gymsSnapshot.empty) {
                continue;
            }

            for (const gymDoc of gymsSnapshot.docs) {
                const gymName = gymDoc.id;

                const attendanceSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, 'Attendance'));

                if (attendanceSnapshot.empty) {
                    continue;
                }

                for (const attendanceDoc of attendanceSnapshot.docs) {
                    const date = attendanceDoc.id;
                    const usersSnapshot = await getDocs(collection(db, cityList, city, gymList, gymName, 'Attendance', date, 'Users'));
                    userCount += usersSnapshot.size;
                }
            }

            cityWiseData.push({
                city: city,
                users: userCount
            });
        }

        return cityWiseData;
    } catch (error) {
        // console.error('Error fetching city-wise data: ', error);
        return cityWiseData;
    }
};

// Fetch Top Blogs
export const fetchAllBlogs = async () => {
    const blogs = [];
    const blogSnapshot = await getDocs(collection(db, 'blogs'));
    for (const blog of blogSnapshot.docs) {
        const blogData = blog.data();
        // console.log("BLOG DATA", blogData.image)
        blogs.push({
            gymDisplayName: blogData.title,
            content: blogData.content,
            image: blogData.image,
            locality: blogData.author,
            time: blogData.timestamp
        });
    }
    return blogs;
};

const filteredGyms = [
    '21fitness',
    'notlisted',
    'arnavgym',
    'setfitness'
]
// Predefined list of NCR cities to be iterated over when city is 'ncr'
const NCR_CITIES = ['ghaziabad', 'noida', 'delhi'];

/**
 * Helper function to fetch gym data for a specific city and add it to the provided array.
 * 
 * @param {string} cityName          - The name of the city for which gyms are being fetched.
 * @param {Array} gymListData        - The array where fetched gym data will be added.
 * @returns {Promise<void>}          - Returns a promise that resolves when gym data has been fetched.
 */
const fetchCityGyms = async (cityName, gymListData) => {
    try {
        const cityGymSnapshot = await getDocs(
            collection(db, cityList, cityName, gymList)
        );

        // Loop through each document in the snapshot (each representing a gym)
        for (const gym of cityGymSnapshot.docs) {
            const gymData = await gym.data();

            // Filtered gyms will skipped if its not development moode
            if (process.env.NODE_ENV !== 'development') {
                if (filteredGyms.includes(gymData.gymName)) continue;
            }

            // Add gym data to the gymListData array
            gymListData.push({
                city            :   cityName,
                classes         :   gymData.classes,
                country         :   gymData.country,
                email           :   gymData.email,
                facilities      :   gymData.facilities,
                phoneNumber     :   gymData.phoneNumber,
                gymContactNumber:   gymData?.gymContactNumber,
                trainRexVerified:   gymData.trainRexVerified,
                gymID           :   gymData.gymID,
                latitude        :   gymData.latitude,
                longitude       :   gymData.longitude,
                gymName         :   gymData.name,
                name            :   gymData.gymDisplayName,
                gymDisplayName  :   gymData.gymDisplayName,
                cityDisplayName :   gymData.cityDisplayName,
                openingTime     :   gymData?.openingTime,
                closingTime     :   gymData?.closingTime,
                timeSlots       :   gymData?.timeSlots,
                rating          :   gymData.rating,
                NoOfReview      :   gymData.NoOfReview,
                locality        :   gymData.locality,
                pinCode         :   gymData.pinCode,
                SumOfRatings    :   gymData.SumOfRatings,
                SumOfReviews    :   gymData.SumOfReviews,
                image           :   gymData.Portfolio === '<url>' ? [''] : [gymData.Portfolio[0]],
                Portfolio       :   gymData.Portfolio === '<url>' ? [''] : gymData.Portfolio,
                address         :   gymData.address,
                price           :   getSellingPrice(gymData.prices?.single?.exclusiveYearlyPrice) || 0,
                prices          :   gymData.prices,
                // mrp             :   gymData.prices?.single?.yearlyPrice || 0,
                // exclusivePrice  :   gymData.prices?.single?.exclusiveYearlyPrice || 0,
                equipmentList   :   gymData.equipmentList,
                totalViews      :   gymData.views?.total || 0,
                thisWeekViews   :   gymData.views?.thisWeek || 0,
                mapLink         :   gymData.mapLink || '',
                isWomensOnly    :   gymData.isWomensOnly ? gymData.isWomensOnly : false,
            });
        }
    } catch (error) {
        console.error('error in fetchCityGyms : ', error);
    }
};

/**
 * Fetch all gyms based on the provided city input.
 * 
 * - If the city is 'ncr', the function will iterate over predefined NCR cities and fetch their gyms.
 * - If the city is not 'ncr', it will fetch gyms only for the specified city.
 * 
 * @param {string} city               - The city for which gym data needs to be fetched. If 'ncr', gyms from all NCR cities are fetched.
 * @returns {Promise<Array>}          - Returns a promise that resolves to an array of gym objects.
 */
export const fetchAllGyms = async (city) => {
    city = removeSpacesAndConvertLowercase(city);
    const gymListData = []; // Initialize an empty array to store gym data

    // If city is 'ncr', fetch gyms for all cities in the NCR region
    if (city === 'ncr') {
        for (const ncrCity of NCR_CITIES) {
            await fetchCityGyms(ncrCity, gymListData);
        }
    } else {
        // Otherwise, fetch gyms for the specified city
        await fetchCityGyms(city, gymListData);
    }

    return sortGyms(gymListData); // Return the list of fetched gyms
};

// Get User City
export async function getLocation() {
    // it will return the following attributes:
    // country, countryCode, regionName, city, lat, lon, zip and timezone
    const res = await axios.get("http://ip-api.com/json");
    return res.data.city
}

const isMonday = () => {
    const today = new Date();
    return today.getDay() === 1; // 1 means Monday in JavaScript Date
};

/**
 * Helps in fetching all the gym data
 * Needed for Product Portfolio Page + Buying the membership
 * @param {String} city
 * @param {String} gymName
 */
export const fetchGymData = async (city, gymName) => {
    console.log('fetching');
    try {
        // Create a reference to the gym document
        const gymRef = doc(db, cityList, city, gymList, gymName);
        const gymDoc = await getDoc(gymRef);

        if (gymDoc.exists()) {
            const gymData = gymDoc.data();

            // Increment the view count if not in development mode
            if (process.env.NODE_ENV !== 'development') {
                let newViews = { total: 1, thisWeek: 1 };

                // Check if views already exist
                if (gymData.views) {
                    // If today is Monday, reset `thisWeek` count
                    const thisWeekViews = isMonday() ? 1 : gymData.views.thisWeek + 1;

                    // Update the view counts
                    newViews = {
                        total: gymData.views.total + 1,  // Increment total views
                        thisWeek: thisWeekViews  // Increment or reset `thisWeek` views
                    };
                }

                // Update the document with new view counts
                await updateDoc(gymRef, {
                    views: newViews
                });
            }

            // Return the gym data
            return gymData;
        } else {
            throw new Error('404: No Gym Found');
        }
    } catch (error) {
        // console.error("Error fetching gym data:", error);
        throw error;
    }
};

export const fetchCities = async () => {
    const cities = [];
    const citySnapshot = await getDocs(collection(db, cityList));

    // Iterate through city snapshot and filter out unwanted cities
    for (const city of citySnapshot.docs) {
        if (city.id !== 'testcity' && city.id !== 'edinburgh') {
            cities.push(city.id);
        }
    }

    // Add "All Cities" as the first option
    cities.unshift('NCR');

    return cities;
};


/**
 * Updates all gyms in the "Cities/delhi/Gyms" collection with exclusive price fields.
 * Skips the gym with the gymName 'gypsy'.
 */
export const updateExclusivePricesForGyms = async () => {
    try {
        // Get all gyms from the "Cities/delhi/Gyms" collection
        const gymsCollectionRef = collection(dbGym, "Gyms");
        const gymsSnapshot = await getDocs(gymsCollectionRef);

        // Iterate through each gym document
        gymsSnapshot.forEach(async (gymDoc) => {
            const gymData = gymDoc.data();
            const { dailyPrice, monthlyPrice, quarterlyPrice, halfYearlyPrice, yearlyPrice } = gymData;

            // Create new exclusive price fields with numeric values
            const exclusivePrices = {
                exclusiveDailyPrice: Number(dailyPrice) || 0,
                exclusiveMonthlyPrice: Number(monthlyPrice) || 0,
                exclusiveQuarterlyPrice: Number(quarterlyPrice) || 0,
                exclusiveHalfYearlyPrice: Number(halfYearlyPrice) || 0,
                exclusiveYearlyPrice: Number(yearlyPrice) || 0,
            };

            // Update the gym document with the new fields
            const gymRef = doc(dbGym, "Gyms", gymDoc.id);
            await updateDoc(gymRef, exclusivePrices);

        });
    } catch (error) {
        // console.error("Error updating exclusive prices for gyms:", error);
    }
};

export const getPlacardProps = (gym, props) => ({
    gymID               : gym.gymID,
    name                : gym.gymDisplayName,
    gymDisplayName      : gym.gymDisplayName,
    gymName             : gym.gymName,
    locality            : gym.locality,
    hidden              : props.heading.includes('BLOG'),
    bg                  : props.bg === 'bg-secondary' ? 'bg-tertiary w-52 lg:w-1/3' : 'bg-secondary w-52 lg:w-1/3',
    textColor           : props.headingColor === 'text-secondary' ? 'text-tertiary' : 'text-secondary',
    locationIconColor   : props.locationIconColor,
    city                : gym.city,
    cityDisplayName     : gym.cityDisplayName,
    classes             : gym?.classes,
    country             : gym?.country,
    email               : gym?.email,
    facilities          : gym?.facilities,
    phoneNumber         : gym?.phoneNumber,
    gymContactNumber    : gym?.gymContactNumber,
    trainRexVerified    : gym.trainRexVerified,
    latitude            : gym?.latitude,
    longitude           : gym?.longitude,
    openingTime         : gym?.openingTime,
    closingTime         : gym?.closingTime,
    timeSlots           : gym?.timeSlots,
    rating              : gym?.rating,
    NoOfReview          : gym?.NoOfReview,
    pinCode             : gym?.pinCode,
    SumOfRatings        : gym?.SumOfRatings,
    SumOfReviews        : gym?.SumOfReviews,
    content             : null,
    time                : null,
    image               : gym.image,
    Portfolio           : gym.Portfolio,
    price               : gym.price ? gym.price : null,
    prices              : gym?.prices,
    equipmentList       : gym?.equipmentList,
    totalViews          : gym?.totalViews,
    thisWeekViews       : gym?.thisWeekViews,
    mapLink             : gym.mapLink || '',
    isWomensOnly        : gym.isWomensOnly ? gym.isWomensOnly : false,
});


export const getSearchedGyms = async (city, searchQuery) => {
    try {
        const gymRef = collection(db, "Cities", city, "Gyms");

        // List of gyms to exclude based on codeName
        const excludedGyms = [
            '21fitness',
            'notlisted',
            'arnavgym',
            'setfitness'
        ];

        const gymSnapshot = await getDocs(gymRef);
        const gymData = gymSnapshot.docs.map((doc) => doc.data());

        // Filter out gyms that have a codeName in the excluded list
        const filteredGyms = gymData.filter(gym => !excludedGyms.includes(gym.codeName));

        // console.log("Filtered Gym Data", filteredGyms);
        return filteredGyms;
    } catch (error) {
        // console.error("Error fetching gyms:", error);
        return null; 
    }
};

export const getDailyTips = () => {
    return "Trying to build muscle? Don't take cold showers after workouts"
}