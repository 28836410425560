import React, { useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, linkWithCredential, updateProfile } from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { doc, setDoc } from '@firebase/firestore';
import icon from '../../assets/CTA_01.webp';
import Modal from "react-modal";

const SignUpPopup = ({ onClose, myData, switchModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [credential, setCredential] = useState();
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [countryCode,] = useState('+91');

  useEffect(() => {
    const initializeRecaptcha = async () => {
        if (!window.recaptchaVerifier && document.getElementById('recaptcha-container')) {
            try {
                window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        console.log("reCAPTCHA solved");
                    },
                    'expired-callback': () => {
                        console.log("reCAPTCHA expired");
                    }
                }, auth);

                await window.recaptchaVerifier.render();
                // console.log('reCAPTCHA initialized');
            } catch (error) {
                // console.error("Error initializing reCAPTCHA", error);
            }
        }
    };

    initializeRecaptcha();

    // // Clean up reCAPTCHA when component unmounts
    return () => {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear();
            window.recaptchaVerifier = null;
        }
    };
  }, []);

  const handlePhoneNumberSubmit = async () => {
    if (!phone) {
      alert("Please enter your phone number.");
      return;
    }
    const fullPhoneNumber = `${countryCode}${phone}`;
    const appVerifier = window.recaptchaVerifier;
  
  
    if (!appVerifier) {
      alert("reCAPTCHA is not initialized. Please reload the page and try again.");
      return;
    }
  

    if (!appVerifier) {
      alert("reCAPTCHA is not initialized. Please reload the page and try again.");
      return;
    }
  
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, appVerifier);
      setVerificationId(confirmationResult.verificationId);
      alert("Verification code sent!");
    } catch (error) {
      console.error("Error during phone number verification", error.message);
      alert("Error verifying phone number. Please try again.");
    }
  };

  const handleCodeVerification = async () => {
    try {
      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      setCredential(phoneCredential);
      setShowEmailPasswordForm(true);
    } catch (error) {
      console.error("Error verifying code", error.message);
      alert("Invalid code. Please try again.");
    }
  };

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: name });

      if (credential) {
        await linkWithCredential(user, credential);
      }

      await addUserToDoc(user);
      alert("Train Rex welcomes you! Happy Fitness!");
    } catch (error) {
      console.error("Error signing up", error.message);
      alert("Error signing up. Please try again.");
    }
  };

  const addUserToDoc = async (user) => {
    try {
      const userRef = doc(db, 'user', user.uid);
      await setDoc(userRef, {
        name: name || '',
        age: age || '',
        displayName: name || '',
        gym: myData.gymName || 'Not specified',
        email: email || '',
        gender: gender || ''
      });
    } catch (error) {
      console.error("Error adding user to Firestore", error.message);
    }
  };

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        contentLabel="Sign Up"
        className="fixed inset-0 flex items-center justify-center z-100 text-black"
        overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
      >
        <div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] lg:max-w-[80%] max-h-[90%] flex flex-col lg:flex-row p-6">
          <div className="w-2/3 lg:w-2/3 flex items-center justify-center mx-auto lg:p-4">
            <img src={icon} alt="Logo" className="object-contain max-h-64 lg:max-h-full" />
          </div>

          <div className="w-full lg:w-1/3 flex flex-col items-center space-y-4 p-4">
            <div className="text-2xl font-semibold text-center">
              {showEmailPasswordForm ? 'Sign Up' : 'Verify Phone Number'}
            </div>
            {!showEmailPasswordForm ? (
              <>
                <input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter phone number"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <button
                  onClick={handlePhoneNumberSubmit}
                  className="w-full bg-primary text-white py-2 rounded-xl"
                >
                  Send Verification Code
                </button>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <button
                  onClick={handleCodeVerification}
                  className="w-full bg-primary text-white py-2 rounded-xl"
                >
                  Verify Code
                </button>
              </>
            ) : (
              <>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm password"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <input
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder="Enter your age"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <input
                  type="text"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  placeholder="Enter your gender"
                  className="w-full px-4 py-2 border rounded-xl"
                />
                <button
                  onClick={handleSignUp}
                  className="w-full bg-primary text-white py-2 rounded-xl"
                >
                  Sign Up
                </button>
              </>
            )}
          </div>

          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-4xl"
          >
            &times;
          </button>
        </div>
      </Modal>
      <div id="recaptcha-container"></div>
    </>
  );
};

export default SignUpPopup;
