import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { getFinalImage } from "../ButtonsLibrary";
import { Link } from "react-router-dom";
import TRLogo from "../../../assets/Icon-512.webp";
import Raasta from "../../../assets/Website_content/dinosaur-fossil.png";
import { getNavigationLink, requestLocationAccess, calculateDistance } from "../MapHelpers/DistanceAndLocationHelpers";
import StarRating from "../../../components/StarRating";
import { LocationIcon } from "../../../assets/WelcomeScreenImage";
import { createGymIcon, createUserLocationMarker } from "../MapHelpers/MapIcons";
import { mapBoxKey } from "../../../config/SecretKeys";
import { themeColors } from "../../../theme";
import { getCoordinatesAndStoreThemInDB } from "../MapHelpers/MapHelper";

// Set your Mapbox access token for authentication with Mapbox services
mapboxgl.accessToken = mapBoxKey;

// Coordinates for fallback if user denies location access
const cityCoordinates = {
    delhi       : { lat: 28.673209384783952, lng: 77.27820607424384, zoom: 13 },
    ghaziabad   : { lat: 28.655716394582665, lng: 77.37594194658801, zoom: 13 },
    noida       : { lat: 28.5355, lng: 77.391, zoom: 12 },
    ncr         : { lat: 28.637210308178965, lng: 77.32793923598803, zoom: 12 },
};

/**
 * Map Component
 * Displays a map with gym locations, user location, and gym details.
 * @param {Object} props - Component props.
 * @param {string} props.selectedCity - City selected by the user.
 * @param {string} props.currentPage - Page context (used to conditionally render details).
 * @param {Array} props.gymArray - Array of gym details including location data.
 */
const Map = ({ selectedCity, currentPage, gymArray }) => {
    const [map, setMap] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [locationDenied, setLocationDenied] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);

    // Initialize the map
    useEffect(() => {
        const cityCenter = cityCoordinates[selectedCity.toLowerCase()] || cityCoordinates.delhi;
        const mapInstance = new mapboxgl.Map({
            container: "map-container",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [cityCenter.lng, cityCenter.lat],
            zoom: cityCenter.zoom,
        });

        setMap(mapInstance);
        mapInstance.on("load", () => mapInstance.resize());

        // Fetch user location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userCoords = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setUserLocation(userCoords);
                    mapInstance.flyTo({ center: [userCoords.lng, userCoords.lat], zoom: 13 });

                    new mapboxgl.Marker({
                        element: createUserLocationMarker(),
                        anchor: "bottom",
                    })
                        .setLngLat([userCoords.lng, userCoords.lat])
                        .addTo(mapInstance);
                },
                () => setLocationDenied(true)
            );
        }
    }, [selectedCity]);

    /**
     * Renders gym markers on the map based on the gym array and user location.
     * If a gym does not have valid latitude or longitude, the coordinates are retrieved using the Mapbox Geocoding API.
     *
     * @param {mapboxgl.Map} map - The Mapbox map instance where the markers will be rendered.
     * @param {Array} gymArray - Array of gym objects containing location data (latitude, longitude, address, etc.).
     * @param {Object} selectedLocation - Currently selected gym location to highlight on the map.
     *
     * @throws {Error} Logs warnings for invalid coordinates and continues execution for other gyms.
     */
    useEffect(() => {
        if (map && gymArray.length) {
            gymArray.forEach(async (loc) => {
                let lat = parseFloat(loc.latitude);
                let lng = parseFloat(loc.longitude);

                // Fetch coordinates if they are missing or invalid
                if (isNaN(lat) || isNaN(lng)) {
                    // console.warn("Invalid coordinates for location:", loc.gymDisplayName);

                    let latAndLng = await getCoordinatesAndStoreThemInDB(
                        {gymName : loc.gymName,     city : loc.city},
                        `${loc.address}, ${loc.cityDisplayName}, ${loc.pincode || ""}`
                    );

                    if (latAndLng === null) {
                        console.warn("Unable to retrieve coordinates for:", loc.gymDisplayName);
                        return; // Skip marker creation for invalid locations
                    }

                    lat = latAndLng.lat;
                    lng = latAndLng.lng;
                }

                // Create a Mapbox marker for the gym
                const gymMarker = new mapboxgl.Marker({
                    element: createGymIcon(loc === selectedLocation), // Highlight selected gym
                    anchor: "bottom",
                })
                    .setLngLat([lng, lat]) // Set marker position
                    .addTo(map);

                // Add click event listener to highlight the selected gym
                gymMarker.getElement().addEventListener("click", () => setSelectedLocation(loc));
            });
        }
    }, [map, gymArray, selectedLocation]);


    useEffect(() => {
        if (selectedLocation) setFadeIn(true);
    }, [selectedLocation]);

    return (
        <div className={currentPage === "marketplace" 
            ? `flex flex-col lg:flex-row h-full justify-between gap-4 px-4 py-4 bg-secondary` 
            : 'flex flex-row h-[300px] md:h-[420px] lg:h-[540px] justify-center py-8 bg-transparent w-full bg-secondary'}
        >
            <div className={currentPage === "marketplace" 
                ? "w-full lg:w-2/3 border-4 rounded-2xl overflow-hidden border-primary" 
                : "w-[100%] lg:w-[79%] rounded-3xl overflow-hidden z-10"}
            >
                <div id="map-container" style={{ width: "100%", height: "600px" }} />
            </div>

            {currentPage === "marketplace" && (
                <div className="w-[98%] lg:w-1/3 flex flex-col justify-start lg:p-4 h-full transition-opacity">
                    {selectedLocation ? (
                        <div className={`flex flex-col justify-between w-full gap-4 ${fadeIn ? "duration-1000 opacity-100" : "duration-0 opacity-0"}`}>
                            <img
                                src={getFinalImage(selectedLocation.image[0]) || TRLogo}
                                alt="Gym-Image"
                                className="rounded-2xl max-h-52 lg:max-h-[320px] min-w-[102%] object-cover"
                            />
                            <div className="flex justify-between items-left w-full">
                                <div className="text-left font-bold text-2xl lg:text-4xl text-tertiary w-7/12">
                                    {selectedLocation.gymDisplayName.length > 15
                                        ? `${selectedLocation.gymDisplayName.slice(0, 15)}...`
                                        : selectedLocation.gymDisplayName}
                                </div>
                                <div className="text-lg w-20 lg:w-20 rounded-md font-bold scale-150 -ml-10 pr-3 lg:pr-5 pt-3 lg:pt-1">
                                    <StarRating rating={selectedLocation.rating} color="primary" showNoOfReviews={false} />
                                </div>
                            </div>
                            <div className="text-tertiary flex items-left gap-1">
                                <span className="w-5 lg:w-7 h-4 lg:h-6">
                                    <LocationIcon color={themeColors.tertiary} />
                                </span>
                                <p className="text-sm -mt-0.5 lg:text-2xl text-tertiary font-bold">
                                    {selectedLocation?.locality || "Locality"}
                                </p>
                            </div>
                            <div className="flex items-left gap-2 mb-2">
                                <p className="text-lg lg:text-xl font-semibold text-primary text-left">
                                    Distance: {calculateDistance(userLocation, parseFloat(selectedLocation.latitude), parseFloat(selectedLocation.longitude), locationDenied, () => requestLocationAccess(setUserLocation, setLocationDenied))}
                                </p>
                                <a
                                    href={getNavigationLink(parseFloat(selectedLocation.latitude), parseFloat(selectedLocation.longitude))}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="group relative flex items-center bg-transparent -mt-2 px-2 rounded-xl text-logoGray font-bold hover:scale-110 transition-all duration-300"
                                >
                                    {/* Image */}
                                    <div className="bg-transparent">
                                        <img src={Raasta} alt="Navigate" className="max-h-8 object-cover" />
                                    </div>

                                    {/* Hover Text */}
                                    <span className="absolute left-full ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-complementPurple text-tertiary font-bold py-1 px-3 rounded-lg whitespace-nowrap">
                                        Get Directions
                                    </span>
                                </a>

                            </div>
                            <div className="flex flex-col items-start justify-between space-y-4">
                                <div className="flex items-end space-x-4">
                                    <div className="text-primary line-through text-sm lg:text-lg">
                                        ₹{selectedLocation.prices.single.yearlyPrice ? selectedLocation.prices.single.yearlyPrice - 1 : " "}/Year
                                    </div>
                                    <div className="font-bold text-xl lg:text-3xl text-tertiary">
                                        ₹{selectedLocation.prices.single.exclusiveYearlyPrice ? selectedLocation.price : " "}/Year
                                    </div>
                                </div>
                                <Link
                                    to={`/${selectedLocation.city}/gyms/${selectedLocation.gymName}`}
                                    className="bg-tertiary rounded-xl py-2 px-4 text-primary font-semibold hover:scale-105 hover:shadow-primary hover:shadow-2xl transition-all duration-300"
                                >
                                    Buy Membership
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className={`text-left text-lg lg:text-2xl font-semibold text-primary pb-4 ${selectedLocation ? "opacity-0" : "opacity-100"}`}>
                            <div className="text-2xl lg:text-6xl font-bold text-tertiary mb-1 md:mb-2">
                                There are {gymArray.length} gyms near you!!
                            </div>
                            <span> Explore and choose the best for your needs! </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Map;
