/* eslint-disable react/jsx-pascal-case */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, Outlet, useParams } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import Marketplace from './Screens/marketplace/Marketplace';
import NavBar_Marketplace_helper from './helperFunctions/Navbar_helpers/Navbar_MarketPlace_helper';
// Rest
import { analytics } from "./config/firebase";
import { logEvent } from "firebase/analytics";
import './index.css';

// Import Screens
const HomePage  = lazy(() => import('./Screens/AppDescription/HomePage'));
const Listing   = lazy(() => import('./Screens/marketplace/Listing'));
const TCPage    = lazy(() => import('./Screens/AppDescription/TCPage'));
const PPPage    = lazy(() => import('./Screens/AppDescription/PPPage'));
const GymCred   = lazy(() => import('./Screens/GymCred'));
const BlogPost  = lazy(() => import('./Screens/marketplace/BlogPost'));
const BlogList  = lazy(() => import('./Screens/marketplace/BlogList'));
const WriteBlog = lazy(() => import('./Screens/marketplace/WriteBlog'));
const Careers   = lazy(() => import('./Screens/AppDescription/careers'));

// Import Components
// Fixed Imports
const Navbar_App_helper = lazy(() => import('./helperFunctions/Navbar_helpers/Navbar_App_helper'));
const GymPage           = lazy(() => import('./Screens/marketplace/GymPage'));
const SearchResult      = lazy(() => import('./Screens/SearchResult'));
const EventContact      = lazy(() => import('./Screens/AppDescription/eventcontact'));
const BottomBar         = lazy(() => import("./components/BottomBar"));
const UserProfile       = lazy(() => import("./Screens/marketplace/UserProfile"));

/**
 * Layout component for Marketplace-related pages
 */
const MarketplaceLayout = ({ selectedCity, onLocationChange }) => (
    <>
        <NavBar_Marketplace_helper selectedCity={selectedCity} onLocationChange={onLocationChange} />
        <Outlet />
    </>
);

/**
 * Layout component for Train Rex App-related pages.
 */
const TrainRexLayout = () => (
    <>
        <Navbar_App_helper />
        <Outlet />
    </>
);

function App() {
    const [selectedCity, setSelectedCity] = useState(() => {
        return localStorage.getItem('selectedCity') || 'Delhi';  // Default to 'delhi'
    });

    // Log an event to Firebase Analytics when the user enters the app
    useEffect(() => {
        const currentTime = new Date().toISOString();
        const userAgent = navigator.userAgent;
        logEvent(analytics, `${userAgent}, ${currentTime}`);
    }, []);

    // Update selectedCity when location changes
    const handleLocationChange = (city) => {
        setSelectedCity(city);
        localStorage.setItem('selectedCity', city);
    };

    return (
        <HelmetProvider>
            <div className="App">
                <Suspense fallback={<div></div>}>
                    <Routes>
                        {/* Marketplace routes with the Marketplace layout */}
                        <Route element={<MarketplaceLayout selectedCity={selectedCity} onLocationChange={handleLocationChange} />}>
                            <Route path="/" element={<Marketplace selectedCity={selectedCity} />} />
                            <Route path="/:city" element={<Marketplace selectedCity={selectedCity} />} />
                            <Route path="/:city/:gyms" element={<CityWrapper component={Listing} />} />
                            <Route path="/blogs" element={<BlogList selectedCity={selectedCity} />} />
                            <Route path="/blogs/:blogSlug" element={<BlogPost selectedCity={selectedCity} />} />
                            <Route path="/WriteBlog" element={<WriteBlog />} />
                            <Route path="/Credentials" element={<GymCred />} />
                            <Route path="/:city/:gyms/:gymSlug" element={<CityWrapper component={GymPage} />} />
                            <Route path="/:city/result" element={<SearchResult />} />
                            <Route path="/profile" element={<UserProfile/>}/>
                            <Route path="/event" element={<EventContact />} />
                        </Route>

                        {/* Train Rex App routes with the TrainRex layout */}
                        <Route element={<TrainRexLayout />}>
                            <Route path="/AppDescription" element={<HomePage />} />
                            <Route path="/AppDescription/Terms&Conditions" element={<TCPage />} />
                            <Route path="/AppDescription/PrivacyPolicy" element={<PPPage />} />
                            <Route path="/AppDescription/Careers" element={<Careers />} />
                        </Route>
                    </Routes>
                </Suspense>

                {/* Lazy load BottomBar */}
                <Suspense fallback={<div></div>}>
                    <BottomBar />
                </Suspense>
            </div>
        </HelmetProvider>
    );
}

/**
 * CityWrapper Component
 * A wrapper component to sync the city from the URL with the selectedCity state.
 * @param {Object} props - The props object.
 * @param {JSX.Element} props.component - The component to render.
 * 
 * @returns {JSX.Element} The rendered component with city synced from the URL.
 */
const CityWrapper = ({ component: Component }) => {
    const { city } = useParams(); // Capture city from URL
    const [selectedCity, setSelectedCity] = useState(() => {
        return localStorage.getItem('selectedCity') || city;
    });

    useEffect(() => {
        // Sync the city from the URL with the state and localStorage
        if (city) {
            setSelectedCity(city);
            localStorage.setItem('selectedCity', city);
        }
    }, [city]);

    return <Component selectedCity={selectedCity} />;
};

export default App;
